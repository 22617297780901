import { Box } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { FC, useEffect } from "react";

import { useLoader, usePayment } from "@/hooks";
import { useLazyGetAllPaymentsQuery } from "@/service";

import { colDefs } from "./PaymentList.map";
import { containerSx, dataGridSx } from "./PaymentList.styles";
import PaymentListToolbar from "./PaymentListToolbar";
import DataGridEmpty from "../DataGridEmpty";

const PaymentList: FC = () => {
  const apiRef = useGridApiRef();

  const [getAllPayments, { isLoading }] = useLazyGetAllPaymentsQuery();
  const { paymentsSt } = usePayment();
  const { setLoader } = useLoader();

  useEffect(() => {
    getAllPayments();
  }, []);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  return (
    <Box sx={containerSx} data-testid='payment-list-component'>
      <DataGrid
        apiRef={apiRef}
        columns={colDefs}
        rows={paymentsSt ?? []}
        pageSizeOptions={[20]}
        disableRowSelectionOnClick
        loading={isLoading}
        sx={dataGridSx}
        slots={{
          toolbar: PaymentListToolbar,
          noRowsOverlay: DataGridEmpty,
        }}
        localeText={{
          filterOperatorContains: "Contiene",
          filterOperatorEquals: "Igual",
          filterOperatorStartsWith: "Comienza con",
          filterOperatorEndsWith: "Termina con",
          filterOperatorIsEmpty: "Está vacío",
          filterOperatorIsNotEmpty: "No esta vacío",
          filterOperatorIsAnyOf: "Es cualquiera de",
          filterPanelOperator: "Criterio",
          filterPanelColumns: "Columnas",
          filterPanelInputLabel: "Valor",
          filterPanelInputPlaceholder: "Ingresa un valor",
          columnMenuSortAsc: "Ordenar ASC",
          columnMenuSortDesc: "Ordenar DESC",
          columnMenuHideColumn: "Ocultar columna",
          columnMenuManageColumns: "Gestionar columnas",
          columnMenuFilter: "Filtrar",
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
      />
    </Box>
  );
};

export default PaymentList;
