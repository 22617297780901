import { Box } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { FC, useEffect } from "react";

import { useLoader, usePermission } from "@/hooks";
import { useLazyGetAllPermissionsQuery } from "@/service";

import { colDefs } from "./PermissionList.map";
import { containerSx, dataGridSx } from "./PermissionList.styles";
import PermissionListToolbar from "./PermissionListToolbar";
import DataGridEmpty from "../DataGridEmpty";

const PermissionList: FC = () => {
  const apiRef = useGridApiRef();

  const [getAllPermissions, { isLoading }] = useLazyGetAllPermissionsQuery();
  const { permissionsSt } = usePermission();
  const { setLoader } = useLoader();

  useEffect(() => {
    getAllPermissions();
  }, []);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  return (
    <Box sx={containerSx} data-testid='permission-list-component'>
      <DataGrid
        apiRef={apiRef}
        columns={colDefs}
        rows={permissionsSt ?? []}
        pageSizeOptions={[20]}
        disableRowSelectionOnClick
        loading={isLoading}
        sx={dataGridSx}
        slots={{
          toolbar: PermissionListToolbar,
          noRowsOverlay: DataGridEmpty,
        }}
        localeText={{
          filterOperatorContains: "Contiene",
          filterOperatorEquals: "Igual",
          filterOperatorStartsWith: "Comienza con",
          filterOperatorEndsWith: "Termina con",
          filterOperatorIsEmpty: "Está vacío",
          filterOperatorIsNotEmpty: "No esta vacío",
          filterOperatorIsAnyOf: "Es cualquiera de",
          filterPanelOperator: "Criterio",
          filterPanelColumns: "Columnas",
          filterPanelInputLabel: "Valor",
          filterPanelInputPlaceholder: "Ingresa un valor",
          columnMenuSortAsc: "Ordenar ASC",
          columnMenuSortDesc: "Ordenar DESC",
          columnMenuHideColumn: "Ocultar columna",
          columnMenuManageColumns: "Gestionar columnas",
          columnMenuFilter: "Filtrar",
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
        }}
      />
    </Box>
  );
};

export default PermissionList;
