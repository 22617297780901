import * as yup from "yup";

export interface SignaturePackagePurchaseValues {
  quantity: number | string;
}

export const validationSchema = yup.object({
  quantity: yup
    .number()
    .min(10, "El valor mínimo de créditos de firma es 10.")
    .max(5000, "El valor máximo de créditos de firma es 5000.")
    .required("Debe seleccionar al menos 10 créditos de firma."),
});

export const initialValues: SignaturePackagePurchaseValues = {
  quantity: "",
};
