import { useDispatch, useSelector } from "react-redux";

import { paymentsSelector } from "@/state/Payment/selectors";
import { setPayments as setPaymentsAction } from "@/state/Payment/slice";
import { IPayment } from "@/state/Payment/slice.types";

const usePayment = () => {
  const dispatch = useDispatch();
  const paymentsSt = useSelector(paymentsSelector);

  const setPayments = (items: IPayment[]) => dispatch(setPaymentsAction(items));

  return {
    paymentsSt,
    setPayments,
  };
};

export default usePayment;
