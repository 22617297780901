import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IPayment, IPaymentState } from "./slice.types";

const initialState: IPaymentState = {
  payments: [],
  error: false,
};

export const paymentSlice = createSlice({
  initialState,
  name: "payments",
  reducers: {
    setPayments: (
      state: IPaymentState,
      { payload }: PayloadAction<IPayment[]>,
    ) => {
      state.payments = payload;
    },
    setError: (
      state: IPaymentState,
      { payload }: PayloadAction<IPaymentState["error"]>,
    ) => {
      state.error = payload;
    },
  },
});

export const { setPayments, setError } = paymentSlice.actions;
