import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IPermission, IPermissionState } from "./slice.types";

const initialState: IPermissionState = {
  _permissions: [],
  error: false,
};

export const permissionSlice = createSlice({
  initialState,
  name: "permissions",
  reducers: {
    setPermissions: (
      state: IPermissionState,
      { payload }: PayloadAction<IPermission[]>,
    ) => {
      state._permissions = payload;
    },
    setError: (
      state: IPermissionState,
      { payload }: PayloadAction<IPermissionState["error"]>,
    ) => {
      state.error = payload;
    },
  },
});

export const { setPermissions, setError } = permissionSlice.actions;
