import { useDispatch, useSelector } from "react-redux";

import { permissionsSelector } from "@/state/Permission/selectors";
import { setPermissions as setPermissionsAction } from "@/state/Permission/slice";
import { IPermission } from "@/state/Permission/slice.types";

const usePermission = () => {
  const dispatch = useDispatch();
  const permissionsSt = useSelector(permissionsSelector);

  const setPermissions = (items: IPermission[]) =>
    dispatch(setPermissionsAction(items));

  return {
    permissionsSt,
    setPermissions,
  };
};

export default usePermission;
