import { Chip, Typography, lighten } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import { theme } from "@/theme";

import PermissionListActions from "./PermissionListActions";

export const colDefs: GridColDef[] = [
  {
    field: "actions",
    headerName: "Acciones",
    width: 100,
    renderCell: ({ row }) => <PermissionListActions row={row} />,
  },
  // {
  //   field: "id",
  //   headerName: "ID",
  //   width: 100,
  //   renderCell: ({ value }) => <Typography variant='body1'>{value}</Typography>,
  //   sortable: false,
  //   filterable: false,
  // },
  {
    field: "code",
    headerName: "Código",
    width: 300,
    renderCell: ({ value }) => (
      <Chip
        label={value}
        sx={{
          background: lighten(theme.palette.grass?.light ?? "", 0.5),
        }}
      />
    ),
    sortable: false,
    filterable: false,
  },
  {
    field: "name",
    headerName: "Nombre",
    width: 300,
    renderCell: ({ value }) => <Typography variant='body1'>{value}</Typography>,
    sortable: false,
    filterable: false,
  },
  {
    field: "description",
    headerName: "Descripción",
    width: 500,
    renderCell: ({ value }) => <Typography variant='body1'>{value}</Typography>,
    sortable: false,
    filterable: false,
  },
];
