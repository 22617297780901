import { HomeOutlined, RecentActorsOutlined } from "@mui/icons-material";
import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { FC } from "react";

import {
  StyledBreadcrumb,
  titleSx,
  toolbarNavSx,
} from "./PermissionList.styles";

const PermissionListToolbar: FC = () => {
  return (
    <GridToolbarContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant='h5'
            fontWeight='bold'
            align='center'
            sx={titleSx}
          >
            Gestión de Permisos
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={toolbarNavSx}>
            <Typography variant='body1' sx={{ mr: 2 }}>
              Te encuentras aquí:
            </Typography>
            <Breadcrumbs aria-label='breadcrumb'>
              <StyledBreadcrumb
                label='Inicio'
                icon={<HomeOutlined fontSize='small' />}
                disabled
              />
              <StyledBreadcrumb
                label='Todos los permisos'
                icon={<RecentActorsOutlined fontSize='small' />}
              />
            </Breadcrumbs>
          </Box>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default PermissionListToolbar;
