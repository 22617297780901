import {
  ArrowBack,
  HomeOutlined,
  RecentActorsOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { FC, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Permissions } from "@/helpers/permissions";
import { useLoader, useMessager } from "@/hooks";
import AuthorizedRoute from "@/routes/AuthorizedRoute";
import { useCreatePermissionMutation } from "@/service";
import { IPermission } from "@/state/Permission/slice.types";

import { initialValues, validationSchema } from "./PermissionForm.schema";
import {
  backButtonSx,
  boxContaineSx,
  StyledBreadcrumb,
  titleSx,
  toolbarNavSx,
} from "./PermissionForm.styles";

const PermissionForm: FC = () => {
  const navigate = useNavigate();
  const [createPermission, { isLoading, isSuccess }] =
    useCreatePermissionMutation();

  const { setMessage } = useMessager();
  const { setLoader } = useLoader();

  const handleOnClose = () => {
    navigate("/dashboard/v1/admin/manage-permissions/list");
  };

  useEffect(() => {
    if (isSuccess) {
      setLoader(false);
      setMessage({
        title: "Permiso creado con éxito",
        body: (
          <Alert severity='success'>
            Se ha creado un nuevo usuario en la plataforma éxitosamente.
          </Alert>
        ),
        open: true,
        buttonCloseTitle: "Entendido",
        onClose: handleOnClose,
        type: "modal",
      });
    }
  }, [isLoading, isSuccess]);

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = (values: IPermission) => {
    setLoader(true);
    createPermission(values);
  };

  return (
    <AuthorizedRoute allowedPermission={Permissions.CREATE_PERMISSION}>
      <Box sx={boxContaineSx}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={toolbarNavSx}>
              <IconButton
                aria-label='go back'
                sx={backButtonSx}
                onClick={goBack}
              >
                <ArrowBack />
              </IconButton>
              <Link
                component={NavLink}
                to='/dashboard/v1/admin'
                variant='subtitle1'
              >
                Volver atrás
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Typography variant='body1' sx={{ mr: 2 }}>
                Te encuentras aquí:
              </Typography>
              <Breadcrumbs aria-label='breadcrumb'>
                <StyledBreadcrumb
                  label='Gestión de permisos'
                  icon={<HomeOutlined fontSize='small' />}
                  disabled
                />
                <StyledBreadcrumb
                  label='Agregar nuevo permiso'
                  icon={<RecentActorsOutlined fontSize='small' />}
                />
              </Breadcrumbs>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <Typography variant='h5' fontWeight='bold' sx={titleSx}>
              Crear permiso
            </Typography>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              validateOnChange={false}
            >
              {({
                handleChange,
                handleSubmit,
                handleBlur,
                values,
                errors,
                touched,
                dirty,
                isValid,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id='code'
                        name='code'
                        value={values.code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label='Código'
                        placeholder='Escribe...'
                        variant='outlined'
                        error={Boolean(touched.code && errors.code)}
                        helperText={
                          Boolean(touched.code && errors.code) && errors.code
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id='name'
                        name='name'
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label='Nombre'
                        placeholder='Escribe...'
                        variant='outlined'
                        error={Boolean(touched.name && errors.name)}
                        helperText={
                          Boolean(touched.name && errors.name) && errors.name
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id='description'
                        name='description'
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label='Descripción'
                        placeholder='Escribe...'
                        variant='outlined'
                        error={Boolean(
                          touched.description && errors.description,
                        )}
                        helperText={
                          Boolean(touched.description && errors.description) &&
                          errors.description
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type='submit'
                        variant='contained'
                        disabled={!dirty || !isValid || isLoading}
                      >
                        Crear permiso
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Box>
    </AuthorizedRoute>
  );
};

export default PermissionForm;
