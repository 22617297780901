import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IMessagerState } from "./slice.types";

const initialState: IMessagerState = {
  open: false,
  type: null,
  title: null,
  body: null,
  buttonCloseTitle: null,
  onClose: undefined,
  extraButton: false,
  extraButtonTitle: null,
  handleExtraButtonClick: null,
  onConfirm: undefined,
  confirmTitle: null,
};

export const messagerSlice = createSlice({
  initialState,
  name: "messager",
  reducers: {
    setMessage: (
      state: IMessagerState,
      { payload }: PayloadAction<IMessagerState>,
    ) => {
      state.type = payload.type;
      state.title = payload.title;
      state.body = payload.body;
      state.buttonCloseTitle = payload.buttonCloseTitle;
      state.onClose = payload.onClose ? payload.onClose : undefined;
      state.open = payload.open;
      state.extraButton = payload.extraButton;
      state.extraButtonTitle = payload.extraButtonTitle;
      state.handleExtraButtonClick = payload.handleExtraButtonClick;
      state.onConfirm = payload.onConfirm;
      state.confirmTitle = payload.confirmTitle;
    },
    clearMessage: (state: IMessagerState) => {
      state.body = initialState.body;
      state.buttonCloseTitle = initialState.buttonCloseTitle;
      state.onClose = initialState.onClose;
      state.subTitle = initialState.subTitle;
      state.type = initialState.type;
      state.open = initialState.open;
      state.title = initialState.title;
      state.extraButton = initialState.extraButton;
      state.extraButtonTitle = initialState.extraButtonTitle;
      state.handleExtraButtonClick = initialState.handleExtraButtonClick;
      state.onConfirm = initialState.onConfirm;
      state.confirmTitle = initialState.confirmTitle;
    },
  },
});

export const { setMessage, clearMessage } = messagerSlice.actions;
