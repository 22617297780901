import * as yup from "yup";

import { IPermission } from "@/state/Permission/slice.types";

export const validationSchema = yup.object({
  code: yup
    .string()
    .min(2, "El código debe contener al menos 2 caractertes")
    .max(255, "El código no puede contener mas de 255 caracteres")
    .required("Por favor introduce el código del permiso"),
  name: yup
    .string()
    .min(2, "El nombre debe contener al menos 2 caractertes")
    .max(255, "El nombre no puede contener mas de 255 caracteres")
    .required("Por favor introduce el nombre del permiso"),
  description: yup
    .string()
    .min(2, "La descripción debe contener al menos 2 caractertes")
    .max(255, "La descripción no puede contener mas de 255 caracteres")
    .required("Por favor introduce la descripción del permiso"),
});

export const initialValues: IPermission = {
  code: "",
  name: "",
  description: "",
};
