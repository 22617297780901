import { EditOutlined, DeleteOutlined } from "@mui/icons-material";
import { Alert, IconButton, Tooltip } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { Permissions } from "@/helpers/permissions";
import { useMessager, useLoader, useSession } from "@/hooks";
import {
  useDeletePermissionMutation,
  useLazyGetAllPermissionsQuery,
} from "@/service";
import { IPermission } from "@/state/Permission/slice.types";

import { blueIconSx, redIconSx } from "./PermissionList.styles";

interface PermissionListActionsProps {
  row: IPermission;
}

const PermissionListActions: FC<PermissionListActionsProps> = ({ row }) => {
  const { setMessage } = useMessager();
  const { setLoader } = useLoader();
  const { canUse } = useSession();
  const [open, setOpen] = useState<boolean>(true);
  const [deletePermission, { isLoading, isSuccess }] =
    useDeletePermissionMutation();
  const [getAllPermissions] = useLazyGetAllPermissionsQuery();

  useEffect(() => {
    if (row.id && isSuccess) {
      setLoader(false);
      setMessage({
        title: "Permiso eliminado con éxito",
        body: (
          <Alert severity='success'>
            Se ha eliminado el permiso en la plataforma éxitosamente.
          </Alert>
        ),
        open: true,
        buttonCloseTitle: "Entendido",
        onClose: handleClose,
        type: "modal",
      });
    }
  }, [isLoading, isSuccess]);

  const handleClose = () => {
    setOpen(false);
    if (isSuccess) {
      getAllPermissions();
    }
  };

  const handleOpen = () => {
    setMessage({
      title: "Eliminar Permiso",
      body: `¿Está seguro que desea eliminar el permiso "${row.code}"? Esta operación no se puede deshacer.`,
      open: true || open,
      buttonCloseTitle: "Cancelar",
      onClose: handleClose,
      onConfirm: handleDelete,
      confirmTitle: "Entendido",
      type: "modal",
    });
  };

  const handleDelete = () => {
    setLoader(true);
    deletePermission(row.id);
  };

  return (
    <>
      {canUse(Permissions.UPDATE_PERMISSION) && (
        <Tooltip title='Editar'>
          <IconButton
            component={NavLink}
            to={`/dashboard/v1/admin/manage-permissions/update/${row.id}`}
          >
            <EditOutlined sx={blueIconSx} />
          </IconButton>
        </Tooltip>
      )}
      {canUse(Permissions.DELETE_PERMISSION) && (
        <Tooltip title='Eliminar'>
          <IconButton onClick={handleOpen}>
            <DeleteOutlined sx={redIconSx} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default PermissionListActions;
