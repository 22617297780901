import { Chip, Typography, lighten } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";

import { theme } from "@/theme";

export const colDefs: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
    renderCell: ({ value }) => <Typography variant='body1'>{value}</Typography>,
    sortable: false,
    filterable: false,
  },
  {
    field: "invoice_id",
    headerName: "Factura",
    width: 200,
    renderCell: ({ value }) => <Typography variant='body1'>{value}</Typography>,
    sortable: false,
    filterable: false,
  },
  {
    field: "type",
    headerName: "Tipo",
    width: 200,
    renderCell: ({ row }) => {
      if (row.signer_id) {
        return (
          <Chip
            label='Firma de Documento'
            sx={{
              background: lighten(theme.palette.atmosphere?.main ?? "", 0.5),
            }}
          />
        );
      }

      if (row.purchase_id) {
        return (
          <Chip
            label='Paquete de Firmas'
            sx={{
              background: lighten(theme.palette.secondary?.main ?? "", 0.5),
            }}
          />
        );
      }

      return <></>;
    },
    sortable: false,
    filterable: false,
  },
  {
    field: "payer",
    headerName: "Pagador",
    width: 300,
    renderCell: ({ row }) => (
      <Typography variant='body1'>
        {row.payer?.first_name} {row.payer?.last_name}{" "}
        {row.payer?.mother_last_name}
      </Typography>
    ),
    sortable: false,
    filterable: false,
  },
  {
    field: "amount",
    headerName: "Monto",
    width: 200,
    renderCell: ({ value }) => (
      <Typography variant='body1'>${value.toFixed(2)} MXN</Typography>
    ),
    sortable: false,
    filterable: false,
  },
  {
    field: "status",
    headerName: "Estado",
    width: 150,
    renderCell: ({ value }) => (
      <Chip
        label={value ? "PAGADO" : "NO PAGADO"}
        sx={{
          background: value
            ? lighten(theme.palette.success?.light ?? "", 0.5)
            : lighten(theme.palette.autumn?.light ?? "", 0.5),
        }}
      />
    ),
    sortable: false,
    filterable: false,
  },
  {
    field: "completed_at",
    headerName: "Fecha de Pago",
    width: 200,
    renderCell: ({ value }) => (
      <Typography variant='body1'>
        {value ? format(value, "dd/MM/yyyy") : ""}
      </Typography>
    ),
    sortable: false,
    filterable: false,
  },
];
